.card-image {
  grid-area: cardImage;
}

.card-content {
  grid-area: cardContent;
}

.card-footer {
  grid-area: cardFooter;
}

.card-header {
  grid-area: cardHeader;
}

.card-title {
  grid-area: cardTitle;
}

.horizontalGrid {
  grid-template-columns: max-content 100%;
  grid-template-areas:
    "cardImage cardContent"
    "cardImage cardContent"
    "cardImage cardContent"
    "cardImage cardFooter";
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}
.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}
